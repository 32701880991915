export const dataMakes = [
    { oemId: 8, oemName: 'Chevrolet' },
    { oemId: 9, oemName: 'Cadillac' },
    { oemId: 10, oemName: 'Buick' },
    { oemId: 11, oemName: 'GMC' },
    { oemId: 12, oemName: 'Hummer' },
    { oemId: 13, oemName: 'Oldsmobile' },
    { oemId: 14, oemName: 'Pontiac' },
    { oemId: 15, oemName: 'Saturn' },
];

export const dataModels = [
    { modelId: 305, modelName: 'Astro Cargo Van', oemId: 8 },
    { modelId: 306, modelName: 'Astro Passenger', oemId: 8 },
    { modelId: 307, modelName: 'Avalanche', oemId: 8 },
    { modelId: 308, modelName: 'Aveo', oemId: 8 },
    { modelId: 309, modelName: 'Blazer', oemId: 8 },
    { modelId: 310, modelName: 'Cavalier', oemId: 8 },
    { modelId: 311, modelName: 'CC4500', oemId: 8 },
    { modelId: 312, modelName: 'CC5500', oemId: 8 },
    { modelId: 313, modelName: 'CC6500', oemId: 8 },
    { modelId: 314, modelName: 'CC7500', oemId: 8 },
    { modelId: 315, modelName: 'CC8500', oemId: 8 },
    { modelId: 316, modelName: 'CC8500 Tandem', oemId: 8 },
    { modelId: 317, modelName: 'Classic', oemId: 8 },
    { modelId: 318, modelName: 'Cobalt', oemId: 8 },
    { modelId: 319, modelName: 'Colorado', oemId: 8 },
    { modelId: 320, modelName: 'Corvette', oemId: 8 },
    { modelId: 321, modelName: 'CT6500', oemId: 8 },
    { modelId: 322, modelName: 'CT7500', oemId: 8 },
    { modelId: 323, modelName: 'CT8500', oemId: 8 },
    { modelId: 324, modelName: 'CT8500 Tandem', oemId: 8 },
    { modelId: 325, modelName: 'Equinox', oemId: 8 },
    { modelId: 326, modelName: 'Express Cargo Van', oemId: 8 },
    { modelId: 327, modelName: 'Express Commercial Cutaway', oemId: 8 },
    { modelId: 328, modelName: 'Express Passenger', oemId: 8 },
    { modelId: 329, modelName: 'Impala', oemId: 8 },
    { modelId: 330, modelName: 'Impala Police Pkg', oemId: 8 },
    { modelId: 331, modelName: 'Malibu', oemId: 8 },
    { modelId: 332, modelName: 'Malibu Maxx', oemId: 8 },
    { modelId: 333, modelName: 'Monte Carlo', oemId: 8 },
    { modelId: 334, modelName: 'Silverado 1500', oemId: 8 },
    { modelId: 335, modelName: 'Silverado 1500 Hybrid', oemId: 8 },
    { modelId: 336, modelName: 'Silverado 1500HD', oemId: 8 },
    { modelId: 337, modelName: 'Silverado 2500HD', oemId: 8 },
    { modelId: 338, modelName: 'Silverado 3500', oemId: 8 },
    { modelId: 339, modelName: 'Silverado SS', oemId: 8 },
    { modelId: 340, modelName: 'SSR', oemId: 8 },
    { modelId: 341, modelName: 'Suburban', oemId: 8 },
    { modelId: 342, modelName: 'Tahoe', oemId: 8 },
    { modelId: 343, modelName: 'Tahoe Police Vehicle', oemId: 8 },
    { modelId: 344, modelName: 'Tahoe Special Service Veh', oemId: 8 },
    { modelId: 345, modelName: 'TrailBlazer', oemId: 8 },
    { modelId: 346, modelName: 'Uplander', oemId: 8 },
    { modelId: 347, modelName: 'Uplander Cargo Van', oemId: 8 },
    { modelId: 348, modelName: 'Venture', oemId: 8 },
    { modelId: 349, modelName: 'Venture Cargo Van', oemId: 8 },
    { modelId: 350, modelName: 'W3S042 W3500 DSL REG', oemId: 8 },
    { modelId: 351, modelName: 'W3S042 W3500 GAS REG', oemId: 8 },
    { modelId: 352, modelName: 'W4S042 W4500 DSL CRW', oemId: 8 },
    { modelId: 353, modelName: 'W4S042 W4500 DSL REG', oemId: 8 },
    { modelId: 354, modelName: 'W4S042 W4500 GAS REG', oemId: 8 },
    { modelId: 355, modelName: 'W5R042 W5500 HD DSL REG', oemId: 8 },
    { modelId: 356, modelName: 'W5S042 W5500 DSL CRW', oemId: 8 },
    { modelId: 357, modelName: 'W5S042 W5500 DSL REG', oemId: 8 },
    { modelId: 358, modelName: 'HHR', oemId: 8 },
    { modelId: 359, modelName: 'Silverado 1500 Classic', oemId: 8 },
    { modelId: 360, modelName: 'Silverado 1500 Classic Hybrid', oemId: 8 },
    { modelId: 361, modelName: 'Silverado 1500HD Classic', oemId: 8 },
    { modelId: 362, modelName: 'Silverado 2500HD Classic', oemId: 8 },
    { modelId: 363, modelName: 'Silverado 3500 Classic', oemId: 8 },
    { modelId: 364, modelName: 'Silverado 3500HD', oemId: 8 },
    { modelId: 365, modelName: 'Silverado SS Classic', oemId: 8 },
    { modelId: 366, modelName: 'Tahoe Police', oemId: 8 },
    { modelId: 367, modelName: 'W4S042 W4500 HD DSL CRW', oemId: 8 },
    { modelId: 368, modelName: 'W4S042 W4500 HD DSL REG', oemId: 8 },
    { modelId: 369, modelName: 'W4S042 W4500 HD GAS REG', oemId: 8 },
    { modelId: 370, modelName: 'Impala Police', oemId: 8 },
    { modelId: 371, modelName: 'Malibu Classic', oemId: 8 },
    { modelId: 372, modelName: 'Tahoe Hybrid', oemId: 8 },
    { modelId: 373, modelName: 'W3500 DSL REG', oemId: 8 },
    { modelId: 374, modelName: 'W3500 DSL REG LSD', oemId: 8 },
    { modelId: 375, modelName: 'W3500 GAS CREW', oemId: 8 },
    { modelId: 376, modelName: 'W3500 GAS REG', oemId: 8 },
    { modelId: 377, modelName: 'W4500 HD DSL CREW', oemId: 8 },
    { modelId: 378, modelName: 'W4500 HD DSL REG AT', oemId: 8 },
    { modelId: 379, modelName: 'W4500 HD DSL REG MT', oemId: 8 },
    { modelId: 380, modelName: 'W4500 HD GAS CREW', oemId: 8 },
    { modelId: 381, modelName: 'W4500 HD GAS REG', oemId: 8 },
    { modelId: 382, modelName: 'W5500 DSL CREW', oemId: 8 },
    { modelId: 383, modelName: 'W5500 DSL REG AT', oemId: 8 },
    { modelId: 384, modelName: 'W5500 DSL REG MT', oemId: 8 },
    { modelId: 385, modelName: 'W5500 HD DSL REG AT', oemId: 8 },
    { modelId: 386, modelName: 'W5500 HD DSL REG MT', oemId: 8 },
    { modelId: 387, modelName: 'Traverse', oemId: 8 },
    { modelId: 388, modelName: 'W4500 HD DSL CREW LSD', oemId: 8 },
    { modelId: 389, modelName: 'W4500 HD DSL REG AT LSD', oemId: 8 },
    { modelId: 390, modelName: 'W4500 HD DSL REG MT LSD', oemId: 8 },
    { modelId: 391, modelName: 'W5500 DSL CREW LSD', oemId: 8 },
    { modelId: 392, modelName: 'W5500 DSL REG AT LSD', oemId: 8 },
    { modelId: 393, modelName: 'W5500 DSL REG MT LSD', oemId: 8 },
    { modelId: 394, modelName: 'W5500 HD DSL REG AT LSD', oemId: 8 },
    { modelId: 395, modelName: 'W5500 HD DSL REG MT LSD', oemId: 8 },
    { modelId: 396, modelName: 'Camaro', oemId: 8 },
    { modelId: 397, modelName: 'Malibu Hybrid', oemId: 8 },
    { modelId: 398, modelName: 'Caprice Police Patrol Vehicle', oemId: 8 },
    { modelId: 399, modelName: 'Cruze', oemId: 8 },
    { modelId: 400, modelName: 'Volt', oemId: 8 },
    { modelId: 402, modelName: 'Captiva Sport Fleet', oemId: 8 },
    { modelId: 403, modelName: 'Sonic', oemId: 8 },
    { modelId: 404, modelName: 'Spark', oemId: 8 },
    { modelId: 405, modelName: 'Corvette Stingray', oemId: 8 },
    { modelId: 406, modelName: 'Impala Limited', oemId: 8 },
    { modelId: 407, modelName: 'Impala Limited Police', oemId: 8 },
    { modelId: 408, modelName: 'Spark EV', oemId: 8 },
    { modelId: 409, modelName: 'SS', oemId: 8 },
    { modelId: 410, modelName: 'City Express Cargo Van', oemId: 8 },
    { modelId: 411, modelName: 'Silverado 2500HD Built After Aug 14', oemId: 8 },
    { modelId: 412, modelName: 'Silverado 3500HD Built After Aug 14', oemId: 8 },
    { modelId: 413, modelName: 'Trax', oemId: 8 },
    { modelId: 414, modelName: '3500 LCF Gas', oemId: 8 },
    { modelId: 415, modelName: '3500HD LCF Diesel', oemId: 8 },
    { modelId: 416, modelName: '4500 LCF Gas', oemId: 8 },
    { modelId: 417, modelName: 'Cruze Limited', oemId: 8 },
    { modelId: 418, modelName: 'Malibu Limited', oemId: 8 },
    { modelId: 419, modelName: '4500HD LCF Diesel', oemId: 8 },
    { modelId: 420, modelName: '4500XD LCF Diesel', oemId: 8 },
    { modelId: 421, modelName: '5500HD LCF Diesel', oemId: 8 },
    { modelId: 422, modelName: '5500XD LCF Diesel', oemId: 8 },
    { modelId: 423, modelName: 'Bolt EV', oemId: 8 },
    { modelId: 424, modelName: '6500XD LCF Diesel', oemId: 8 },
    { modelId: 425, modelName: 'Silverado 1500 LD', oemId: 8 },
    { modelId: 426, modelName: 'Silverado 3500HD CC', oemId: 8 },
    { modelId: 427, modelName: 'Silverado MD', oemId: 8 },
    { modelId: 449, modelName: 'Bolt EUV', oemId: 8 },
    { modelId: 450, modelName: 'Silverado 1500 LTD', oemId: 8 },
    { modelId: 578, modelName: '7500XD LCF Diesel', oemId: 8 },
    { modelId: 750, modelName: '3500 HG LCF Gas', oemId: 8 },
    { modelId: 751, modelName: '4500 HD LCF Diesel', oemId: 8 },
    { modelId: 752, modelName: '4500 HG LCF Gas', oemId: 8 },
    { modelId: 753, modelName: '4500 XD LCF Diesel', oemId: 8 },
    { modelId: 754, modelName: '5500 HD LCF Diesel', oemId: 8 },
    { modelId: 755, modelName: '5500 HG LCF Gas', oemId: 8 },
    { modelId: 756, modelName: '5500 XD LCF Diesel', oemId: 8 },
    { modelId: 757, modelName: '5500 XG LCF Gas', oemId: 8 },
    { modelId: 758, modelName: '6500 XD LCF Diesel', oemId: 8 },
    { modelId: 759, modelName: '7500 XD LCF Diesel', oemId: 8 },
    { modelId: 760, modelName: 'Silverado EV', oemId: 8 },
    { modelId: 966, modelName: 'Orlando', oemId: 8 },
    { modelId: 967, modelName: 'Blazer EV', oemId: 8 },
    { modelId: 1037, modelName: 'Equinox EV', oemId: 8 },
    { modelId: 1038, modelName: 'Traverse Limited', oemId: 8 },
    { modelId: 495, modelName: 'CTS', oemId: 9 },
    { modelId: 496, modelName: 'CTS-V', oemId: 9 },
    { modelId: 497, modelName: 'DTS', oemId: 9 },
    { modelId: 498, modelName: 'DTS Professional', oemId: 9 },
    { modelId: 499, modelName: 'Escalade', oemId: 9 },
    { modelId: 500, modelName: 'Escalade ESV', oemId: 9 },
    { modelId: 501, modelName: 'Escalade EXT', oemId: 9 },
    { modelId: 502, modelName: 'SRX', oemId: 9 },
    { modelId: 503, modelName: 'STS', oemId: 9 },
    { modelId: 504, modelName: 'STS-V', oemId: 9 },
    { modelId: 505, modelName: 'XLR', oemId: 9 },
    { modelId: 506, modelName: 'XLR-V', oemId: 9 },
    { modelId: 507, modelName: 'Escalade Hybrid', oemId: 9 },
    { modelId: 508, modelName: 'CTS Sedan', oemId: 9 },
    { modelId: 509, modelName: 'CTS Wagon', oemId: 9 },
    { modelId: 510, modelName: 'CTS Coupe', oemId: 9 },
    { modelId: 511, modelName: 'CTS-V Coupe', oemId: 9 },
    { modelId: 512, modelName: 'CTS-V Sedan', oemId: 9 },
    { modelId: 513, modelName: 'CTS-V Wagon', oemId: 9 },
    { modelId: 514, modelName: 'ATS', oemId: 9 },
    { modelId: 515, modelName: 'XTS', oemId: 9 },
    { modelId: 516, modelName: 'ELR', oemId: 9 },
    { modelId: 517, modelName: 'ATS Coupe', oemId: 9 },
    { modelId: 518, modelName: 'ATS Sedan', oemId: 9 },
    { modelId: 519, modelName: 'ATS-V Coupe', oemId: 9 },
    { modelId: 520, modelName: 'ATS-V Sedan', oemId: 9 },
    { modelId: 521, modelName: 'CT6', oemId: 9 },
    { modelId: 522, modelName: 'XT5', oemId: 9 },
    { modelId: 523, modelName: 'CT6-V', oemId: 9 },
    { modelId: 524, modelName: 'XT4', oemId: 9 },
    { modelId: 525, modelName: 'CT4', oemId: 9 },
    { modelId: 526, modelName: 'CT5', oemId: 9 },
    { modelId: 527, modelName: 'XT6', oemId: 9 },
    { modelId: 528, modelName: 'CT4-V', oemId: 9 },
    { modelId: 529, modelName: 'CT5-V', oemId: 9 },
    { modelId: 579, modelName: 'LYRIQ', oemId: 9 },
    { modelId: 530, modelName: 'LaCrosse', oemId: 10 },
    { modelId: 531, modelName: 'Lucerne', oemId: 10 },
    { modelId: 532, modelName: 'Rainier', oemId: 10 },
    { modelId: 533, modelName: 'Rendezvous', oemId: 10 },
    { modelId: 534, modelName: 'Terraza', oemId: 10 },
    { modelId: 535, modelName: 'Enclave', oemId: 10 },
    { modelId: 536, modelName: 'Regal', oemId: 10 },
    { modelId: 537, modelName: 'Verano', oemId: 10 },
    { modelId: 538, modelName: 'Encore', oemId: 10 },
    { modelId: 539, modelName: 'Cascada', oemId: 10 },
    { modelId: 540, modelName: 'Envision', oemId: 10 },
    { modelId: 541, modelName: 'Regal Sportback', oemId: 10 },
    { modelId: 542, modelName: 'Regal TourX', oemId: 10 },
    { modelId: 543, modelName: 'Encore GX', oemId: 10 },
    { modelId: 965, modelName: 'Envista', oemId: 10 },
    { modelId: 350, modelName: 'W3S042 W3500 DSL REG', oemId: 11 },
    { modelId: 351, modelName: 'W3S042 W3500 GAS REG', oemId: 11 },
    { modelId: 352, modelName: 'W4S042 W4500 DSL CRW', oemId: 11 },
    { modelId: 353, modelName: 'W4S042 W4500 DSL REG', oemId: 11 },
    { modelId: 354, modelName: 'W4S042 W4500 GAS REG', oemId: 11 },
    { modelId: 355, modelName: 'W5R042 W5500 HD DSL REG', oemId: 11 },
    { modelId: 356, modelName: 'W5S042 W5500 DSL CRW', oemId: 11 },
    { modelId: 357, modelName: 'W5S042 W5500 DSL REG', oemId: 11 },
    { modelId: 367, modelName: 'W4S042 W4500 HD DSL CRW', oemId: 11 },
    { modelId: 368, modelName: 'W4S042 W4500 HD DSL REG', oemId: 11 },
    { modelId: 369, modelName: 'W4S042 W4500 HD GAS REG', oemId: 11 },
    { modelId: 373, modelName: 'W3500 DSL REG', oemId: 11 },
    { modelId: 374, modelName: 'W3500 DSL REG LSD', oemId: 11 },
    { modelId: 375, modelName: 'W3500 GAS CREW', oemId: 11 },
    { modelId: 376, modelName: 'W3500 GAS REG', oemId: 11 },
    { modelId: 377, modelName: 'W4500 HD DSL CREW', oemId: 11 },
    { modelId: 378, modelName: 'W4500 HD DSL REG AT', oemId: 11 },
    { modelId: 379, modelName: 'W4500 HD DSL REG MT', oemId: 11 },
    { modelId: 380, modelName: 'W4500 HD GAS CREW', oemId: 11 },
    { modelId: 381, modelName: 'W4500 HD GAS REG', oemId: 11 },
    { modelId: 382, modelName: 'W5500 DSL CREW', oemId: 11 },
    { modelId: 383, modelName: 'W5500 DSL REG AT', oemId: 11 },
    { modelId: 384, modelName: 'W5500 DSL REG MT', oemId: 11 },
    { modelId: 385, modelName: 'W5500 HD DSL REG AT', oemId: 11 },
    { modelId: 386, modelName: 'W5500 HD DSL REG MT', oemId: 11 },
    { modelId: 388, modelName: 'W4500 HD DSL CREW LSD', oemId: 11 },
    { modelId: 389, modelName: 'W4500 HD DSL REG AT LSD', oemId: 11 },
    { modelId: 390, modelName: 'W4500 HD DSL REG MT LSD', oemId: 11 },
    { modelId: 391, modelName: 'W5500 DSL CREW LSD', oemId: 11 },
    { modelId: 392, modelName: 'W5500 DSL REG AT LSD', oemId: 11 },
    { modelId: 393, modelName: 'W5500 DSL REG MT LSD', oemId: 11 },
    { modelId: 394, modelName: 'W5500 HD DSL REG AT LSD', oemId: 11 },
    { modelId: 395, modelName: 'W5500 HD DSL REG MT LSD', oemId: 11 },
    { modelId: 451, modelName: 'Canyon', oemId: 11 },
    { modelId: 452, modelName: 'Envoy', oemId: 11 },
    { modelId: 453, modelName: 'Envoy XL', oemId: 11 },
    { modelId: 454, modelName: 'Savana Cargo Van', oemId: 11 },
    { modelId: 455, modelName: 'Savana Cutaway', oemId: 11 },
    { modelId: 456, modelName: 'Savana Passenger', oemId: 11 },
    { modelId: 457, modelName: 'Sierra 1500', oemId: 11 },
    { modelId: 458, modelName: 'Sierra 1500 Hybrid', oemId: 11 },
    { modelId: 459, modelName: 'Sierra 1500HD', oemId: 11 },
    { modelId: 460, modelName: 'Sierra 2500HD', oemId: 11 },
    { modelId: 461, modelName: 'Sierra 3500', oemId: 11 },
    { modelId: 462, modelName: 'Sierra Denali', oemId: 11 },
    { modelId: 463, modelName: 'TC4500', oemId: 11 },
    { modelId: 464, modelName: 'TC5500', oemId: 11 },
    { modelId: 465, modelName: 'TC6500', oemId: 11 },
    { modelId: 466, modelName: 'TC7500', oemId: 11 },
    { modelId: 467, modelName: 'TC8500', oemId: 11 },
    { modelId: 468, modelName: 'TC8500 Tandem', oemId: 11 },
    { modelId: 469, modelName: 'TT6500', oemId: 11 },
    { modelId: 470, modelName: 'TT7500', oemId: 11 },
    { modelId: 471, modelName: 'TT8500', oemId: 11 },
    { modelId: 472, modelName: 'TT8500 Tandem', oemId: 11 },
    { modelId: 473, modelName: 'Yukon', oemId: 11 },
    { modelId: 474, modelName: 'Yukon Denali', oemId: 11 },
    { modelId: 475, modelName: 'Yukon XL', oemId: 11 },
    { modelId: 476, modelName: 'Yukon XL Denali', oemId: 11 },
    { modelId: 477, modelName: 'Acadia', oemId: 11 },
    { modelId: 478, modelName: 'Sierra 1500 Classic', oemId: 11 },
    { modelId: 479, modelName: 'Sierra 1500 Classic Hybrid', oemId: 11 },
    { modelId: 480, modelName: 'Sierra 1500HD Classic', oemId: 11 },
    { modelId: 481, modelName: 'Sierra 2500HD Classic', oemId: 11 },
    { modelId: 482, modelName: 'Sierra 3500 Classic', oemId: 11 },
    { modelId: 483, modelName: 'Sierra 3500HD', oemId: 11 },
    { modelId: 484, modelName: 'Sierra Denali Classic', oemId: 11 },
    { modelId: 485, modelName: 'Yukon Hybrid', oemId: 11 },
    { modelId: 486, modelName: 'Yukon Hybrid Denali', oemId: 11 },
    { modelId: 487, modelName: 'Savana Commercial Cutaway', oemId: 11 },
    { modelId: 488, modelName: 'Terrain', oemId: 11 },
    { modelId: 489, modelName: 'Sierra 2500HD available WiFi', oemId: 11 },
    { modelId: 490, modelName: 'Sierra 3500HD available WiFi', oemId: 11 },
    { modelId: 491, modelName: 'Acadia Limited', oemId: 11 },
    { modelId: 492, modelName: 'Sierra 1500 Limited', oemId: 11 },
    { modelId: 493, modelName: 'Sierra 3500HD CC', oemId: 11 },
    { modelId: 494, modelName: 'HUMMER EV', oemId: 11 },
    { modelId: 580, modelName: 'HUMMER EV Pickup', oemId: 11 },
    { modelId: 749, modelName: 'HUMMER EV SUV', oemId: 11 },
    { modelId: 969, modelName: 'Sierra 2500HD Built After Aug 14', oemId: 11 },
    { modelId: 970, modelName: 'Sierra 3500HD Built After Aug 14', oemId: 11 },
    { modelId: 1024, modelName: 'Sierra EV', oemId: 11 },
    { modelId: 544, modelName: 'H1', oemId: 12 },
    { modelId: 545, modelName: 'H2', oemId: 12 },
    { modelId: 546, modelName: 'H3', oemId: 12 },
    { modelId: 547, modelName: 'H3 SUV', oemId: 12 },
    { modelId: 548, modelName: 'H3T', oemId: 12 },
    { modelId: 549, modelName: 'G6', oemId: 14 },
    { modelId: 550, modelName: 'Grand Prix', oemId: 14 },
    { modelId: 551, modelName: 'GTO', oemId: 14 },
    { modelId: 552, modelName: 'Montana SV6', oemId: 14 },
    { modelId: 553, modelName: 'Solstice', oemId: 14 },
    { modelId: 554, modelName: 'Torrent', oemId: 14 },
    { modelId: 555, modelName: 'Vibe', oemId: 14 },
    { modelId: 556, modelName: 'G5', oemId: 14 },
    { modelId: 557, modelName: 'G8', oemId: 14 },
    { modelId: 558, modelName: 'G3', oemId: 14 },
    { modelId: 559, modelName: 'Ion', oemId: 15 },
    { modelId: 560, modelName: 'Relay', oemId: 15 },
    { modelId: 561, modelName: 'VUE', oemId: 15 },
    { modelId: 562, modelName: 'Aura', oemId: 15 },
    { modelId: 563, modelName: 'Outlook', oemId: 15 },
    { modelId: 564, modelName: 'Sky', oemId: 15 },
    { modelId: 565, modelName: 'Astra', oemId: 15 },
    { modelId: 566, modelName: 'Aura Hybrid', oemId: 15 },
    { modelId: 567, modelName: 'VUE Hybrid', oemId: 15 },
];
