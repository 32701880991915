import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import {
    requestVehicleByOemOdata,
    requestPatchVehicle,
    requestBulkUpdateVehicles,
    requestUpdateVehicleDisplayingTags,
} from 'api/vehicleInfo';
import { requestTaggerStatisticsForVehicle } from 'api/RepairProcedureApi';
import { useParams } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import {
    VM_ACTIONS,
    VM_OPERATOR_TYPE,
    VM_TABLE_COLUMN_NAME_BY_ID,
    VM_TABLE_COLUMN_ID,
    VM_INPUT_FILTER_VALUE,
} from 'components/locations/VehicleProcess/VehicleManagement/VehicleManagementConfig';
import { SORT_ORDER } from 'enums/SortOrderEnum';

// To concatenate vehicle info into human-readable string of vehicle name
const setVehicleDetails = v => {
    v.vehicleDetails = [v.year.yearValue, v.model.modelName, v.trim.trimName].join(' ');
};

// TODO Maybe extract this for reusability??
const setVehicleModelInfo = v => {
    const visualModel = v.visualModel;

    if (!visualModel || !visualModel.path) return;

    const pathParts = visualModel.path.split('/');

    if (!pathParts.length || pathParts.length < 3) {
        v.visualModel.visualModelName = visualModel.fileName.split('.').slice(0, -1).join('.');
    }

    const modIdx = pathParts.indexOf('models');

    if (modIdx < 0) {
        v.visualModel.visualModelName = visualModel.fileName.split('.').slice(0, -1).join('.');
    }

    let make = pathParts[modIdx + 1];
    let model = pathParts[modIdx + 2];
    let year = pathParts[modIdx + 3];

    v.visualModel.visualModelName = `${year} ${make} ${model}`;
};

const useVehicleManagementTool = () => {
    const { oemId, modelId } = useParams();
    const [vehicles, setVehicles] = useState([]);
    const [searchedVehicles, setSearchedVehicles] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isBulkAssignModalOpen, setIsBulkAssignModalOpen] = useState(false);
    const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
    const [visualModelViewerModel, setVisualModalViewerModel] = useState(null);
    const checkboxSelectionRef = useRef({
        isShiftKeyDown: false, // true if the user holding shift while checking
        isAdding: false, // true if the last action was to check a checkbox
    });
    const [filters, setFilters] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    const confirmationModelRef = useRef();
    const filterId = useRef(0);

    const [sortedColumn, setSortedColumn] = useState([]);
    const [sortOrder, setSortOrder] = useState({});
    const [sortedVehicles, setSortedVehicles] = useState([]);
    const [originalOrder, setOriginalOrder] = useState(true);

    useEffect(() => {
        (async () => {
            if (oemId) {
                try {
                    setIsLoading(true);

                    let vehicles = await requestVehicleByOemOdata(oemId, modelId);
                    vehicles.forEach(v => setVehicleDetails(v));
                    vehicles.forEach(v => setVehicleModelInfo(v));

                    //sort by trim then by year
                    vehicles.sort((v1, v2) => {
                        if (v1.year.yearValue > v2.year.yearValue) return -1;
                        if (v1.year.yearValue < v2.year.yearValue) return 1;
                        if (v1.trim.trimName < v2.trim.trimName) return -1;
                        if (v1.trim.trimName > v2.trim.trimName) return 1;
                        return 0;
                    });

                    // to remove redundant calls of getting tagger stats for all vehicles
                    // we group them by yearId, oemId, modelId and trimId and trim filtering bit
                    let vehicleGroup = [];
                    vehicles.forEach(v => {
                        const vehicleTrimId = v.isTrimLevelFilteringEnabled ? v.trim.trimId : null;
                        const found = vehicleGroup.find(
                            vg =>
                                vg.yearId === v.year.yearId &&
                                vg.oemId === v.oem.oemId &&
                                vg.modelId === v.model.modelId &&
                                vg.trimId === vehicleTrimId
                        );
                        if (found) {
                            found.vehicles.push(v);
                        } else {
                            vehicleGroup.push({
                                yearId: v.year.yearId,
                                oemId: v.oem.oemId,
                                modelId: v.model.modelId,
                                trimId: vehicleTrimId,
                                vehicles: [v],
                            });
                        }
                    });
                    const promises = vehicleGroup.map(vg =>
                        requestTaggerStatisticsForVehicle(vg.yearId, vg.oemId, vg.modelId, vg.trimId)
                    );
                    const taggerStats = await Promise.all(promises);
                    vehicleGroup.forEach((vg, i) => {
                        vg.vehicles.forEach(
                            v =>
                                (v.taggerStatistics = {
                                    unresolvedFlagCount: taggerStats[i].unresolvedFlagCount,
                                    activeIncompletedTagCount: taggerStats[i].activeIncompletedTagCount,
                                })
                        );
                    });
                    setVehicles(vehicles);
                } catch (error) {
                    showToast(error);
                } finally {
                    setIsLoading(false);
                }
            }
        })();
    }, [oemId, modelId, showToast]);

    useEffect(() => {
        setSearchValue('');
        //Reset the selection when new model is selected
        setSelectedVehicleIds([]);
        setOriginalOrder(true);
        setSortedColumn([]);
        setSortOrder({});
        InitializeFilter(null);
    }, [modelId, InitializeFilter]);

    useEffect(() => {
        if (vehicles.length === 0) return;

        const getDefaultLocaleOption = () => ({
            key: 1,
            value: VM_INPUT_FILTER_VALUE.EMPTY,
            text: 'No Locale',
        });

        // Helper function to generate locale options from vehicles
        const getLocaleOptions = vehicleArr => {
            const countryCodes = vehicleArr.reduce((set, v) => {
                v.locales.forEach(l => set.add(l));
                return set;
            }, new Set());

            let counter = 1;
            return Array.from(countryCodes).map(country => ({
                key: ++counter,
                value: country,
                text: country,
            }));
        };

        const localeOptions = [getDefaultLocaleOption(), ...getLocaleOptions(vehicles)];

        VM_ACTIONS[VM_TABLE_COLUMN_ID.LOCALE_NAME].inputType.props.options = localeOptions;
    }, [vehicles]);

    const InitializeFilter = useCallback(
        action => {
            setFilters([
                {
                    id: ++filterId.current,
                    columnId: '-1',
                    operatorId: '-1',
                    inputValue: '',
                    action: action,
                },
            ]);
        },
        [setFilters]
    );

    const handleAddFilterButtonClick = useCallback(() => {
        setFilters([
            ...filters,
            { id: ++filterId.current, columnId: -1, operatorId: -1, inputValue: '', action: null },
        ]);
    }, [filters]);

    const handleRemoveFilterButtonClick = useCallback(
        id => {
            const filteredFilters = filters.filter(filter => filter.id !== id);
            if (filteredFilters && filteredFilters.length > 0) setFilters(filteredFilters);
            else InitializeFilter(null);
        },
        [filters, InitializeFilter]
    );

    const updateOperatorId = (isColumnChange, operatorId) => {
        if (isColumnChange) {
            return -1;
        }

        return operatorId;
    };

    const updateInputValue = (isColumnChange, inputValue) => {
        if (isColumnChange) {
            return '';
        }

        return inputValue;
    };

    const handleUpdate = useCallback(
        (id, columnId, operatorId, filterInputValue, isColumnChange) => {
            let action = { ...VM_ACTIONS[columnId] };
            let updatedFilters = filters.map(filter => {
                if (filter.id === id) {
                    return {
                        ...filter,
                        id: id,
                        columnId: columnId,
                        operatorId: updateOperatorId(isColumnChange, operatorId),
                        inputValue: updateInputValue(isColumnChange, filterInputValue),
                        action: action,
                    };
                }
                return filter;
            });
            setFilters(updatedFilters);
        },
        [filters]
    );

    const handleSearchButtonClick = useCallback(() => {
        const filteredVehicles = vehicles.filter(vehicle =>
            filters.every(filter => {
                if (filter && filter.inputValue) {
                    const vehicleValue = getVehicleColumnValue(vehicle, filter.columnId);

                    switch (filter.operatorId) {
                        case VM_OPERATOR_TYPE.CONTAINS.id:
                            return vehicleValue.includes(filter.inputValue);
                        case VM_OPERATOR_TYPE.NOT_CONTAIN.id:
                            return !vehicleValue.includes(filter.inputValue);
                        case VM_OPERATOR_TYPE.CONTAINS_EXCLUSIVELY.id:
                            return vehicleValue.length === 1 && vehicleValue[0] === filter.inputValue;
                    }

                    const matchFound = vehicleValue === filter.inputValue;
                    return filter.operatorId === VM_OPERATOR_TYPE.EQUAL.id ? matchFound : !matchFound;
                } else {
                    return true;
                }
            })
        );
        setSearchedVehicles(filteredVehicles);
    }, [setSearchedVehicles, vehicles, filters, getVehicleColumnValue]);

    const getTotalPendingFlagsOrTagsForVehicle = useCallback(
        (vehicle, columnId) =>
            columnId === VM_TABLE_COLUMN_ID.DISPLAY_TAG_STATE
                ? vehicle.taggerStatistics.activeIncompletedTagCount + vehicle.taggerStatistics.unresolvedFlagCount
                : 0,
        []
    );

    const getVehicleColumnValue = useCallback(
        (vehicle, columnId) => {
            const value = vehicle[VM_TABLE_COLUMN_NAME_BY_ID[columnId]];
            const totalUnresolvedFlagsOrTags = getTotalPendingFlagsOrTagsForVehicle(vehicle, columnId);
            if (columnId === VM_TABLE_COLUMN_ID.DISPLAY_TAG_STATE) {
                return value
                    ? VM_INPUT_FILTER_VALUE.TRUE
                    : totalUnresolvedFlagsOrTags === 0
                    ? VM_INPUT_FILTER_VALUE.FALSE
                    : VM_INPUT_FILTER_VALUE.NOT_COMPLETED;
            } else if (columnId === VM_TABLE_COLUMN_ID.LOCALE_NAME) {
                // Handle if locales are empty or null
                return !value || value.length === 0 ? [VM_INPUT_FILTER_VALUE.EMPTY] : value;
            }
            return value ? VM_INPUT_FILTER_VALUE.TRUE : VM_INPUT_FILTER_VALUE.FALSE;
        },
        [getTotalPendingFlagsOrTagsForVehicle]
    );

    // reset the selected vehicle ids when search value change
    useEffect(() => setSelectedVehicleIds([]), [searchValue]);

    useEffect(() => {
        if (searchValue !== '') {
            setSearchedVehicles(
                vehicles.slice().filter(v => v.vehicleDetails.toLowerCase().includes(searchValue.toLowerCase()))
            );
        } else setSearchedVehicles(vehicles);
    }, [searchValue, vehicles]);

    const updateTrimFiltering = async (vehicleId, isTrimLevelFilteringEnabled) => {
        await requestPatchVehicle(vehicleId, isTrimLevelFilteringEnabled);
        setVehicles(prevState => {
            let newVehicles = prevState.slice();
            newVehicles.find(v => v.vehicleId === vehicleId).isTrimLevelFilteringEnabled = isTrimLevelFilteringEnabled;
            return newVehicles;
        });
    };

    const handleBulkEditButtonClick = useCallback(() => {
        setIsBulkAssignModalOpen(true);
    }, []);

    const handleCheckboxChange = useCallback(
        vehicle => {
            const { vehicleId } = vehicle;
            setSelectedVehicleIds(prev => {
                let newSelectedIds = [...prev];
                if (prev.find(id => id === vehicleId)) {
                    if (checkboxSelectionRef.current) {
                        checkboxSelectionRef.current.isAdding = false;
                    }
                    newSelectedIds = newSelectedIds.filter(id => id !== vehicleId);
                } else if (
                    checkboxSelectionRef.current &&
                    checkboxSelectionRef.current.isAdding &&
                    checkboxSelectionRef.current.isShiftKeyDown &&
                    newSelectedIds.length > 0
                ) {
                    // bulk checkbox selection
                    const lastIndex = searchedVehicles.findIndex(v => v.vehicleId === newSelectedIds.at(-1));
                    const currentIndex = searchedVehicles.findIndex(v => v.vehicleId === vehicleId);
                    if (lastIndex === -1 || currentIndex === -1) {
                        throw new Error('Cannot find selected item index');
                    }

                    const from = lastIndex > currentIndex ? currentIndex : lastIndex;
                    const to = currentIndex + lastIndex - from;
                    searchedVehicles.slice(from, to + 1).forEach(v => {
                        if (!newSelectedIds.find(id => v.vehicleId === id)) {
                            newSelectedIds = [...newSelectedIds, v.vehicleId];
                        }
                    });
                    // end of bulk checkbox selection
                    checkboxSelectionRef.current.isAdding = false;
                } else {
                    newSelectedIds = [...newSelectedIds, vehicleId];
                    checkboxSelectionRef.current.isAdding = true;
                }
                return newSelectedIds;
            });
        },
        [searchedVehicles]
    );

    const handleBulkCheckboxChange = useCallback(() => {
        setSelectedVehicleIds(prev =>
            prev.length !== searchedVehicles.length ? searchedVehicles.map(v => v.vehicleId) : []
        );
    }, [searchedVehicles]);

    const handleCheckboxKeyDown = useCallback(e => {
        if (
            e.key === 'Shift' &&
            checkboxSelectionRef.current &&
            checkboxSelectionRef.current.isShiftKeyDown === false
        ) {
            checkboxSelectionRef.current.isShiftKeyDown = true;
        }
    }, []);

    const handleCheckboxKeyUp = useCallback(e => {
        if (e.key === 'Shift' && checkboxSelectionRef.current && checkboxSelectionRef.current.isShiftKeyDown === true) {
            checkboxSelectionRef.current.isShiftKeyDown = false;
        }
    }, []);

    const handleModalClose = useCallback(() => {
        setIsBulkAssignModalOpen(false);
    }, []);

    const handleModalSave = useCallback(
        async payload => {
            setIsBulkAssignModalOpen(false);
            try {
                incrementLoading();
                const updatedVehicles = await requestBulkUpdateVehicles(
                    selectedVehicleIds,
                    payload.trimLevelFiltering,
                    payload.visualModelId,
                    payload.oemModelId,
                    oemId,
                    payload.displayTags
                );
                updatedVehicles.forEach(v => setVehicleDetails(v));
                setVehicles(prev =>
                    prev.map(v => {
                        const found = updatedVehicles.find(uv => uv.vehicleId === v.vehicleId);
                        if (found) {
                            return { ...v, ...found };
                        }
                        return v;
                    })
                );
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        },
        [selectedVehicleIds, oemId, showToast, incrementLoading, decrementLoading]
    );

    const isBulkCheckboxChecked = searchedVehicles.length && selectedVehicleIds.length === searchedVehicles.length;

    const updateDisplayingTags = async (vehicleId, isDisplayingTagsEnabled) => {
        try {
            incrementLoading();
            const updatedVehicle = await requestUpdateVehicleDisplayingTags(vehicleId, isDisplayingTagsEnabled);
            setVehicles(prev => {
                const newVehicles = prev.map(v => {
                    if (v.vehicleId === updatedVehicle.vehicleId) {
                        return { ...v, isDisplayingTagsEnabled: updatedVehicle.isDisplayingTagsEnabled };
                    }
                    return v;
                });
                return newVehicles;
            });
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleVehicleDisplayingTagsYesButtonClick = (vehicleId, isDisplayingTagsEnabled) => {
        if (confirmationModelRef.current) {
            confirmationModelRef.current.open(
                async () => await updateDisplayingTags(vehicleId, isDisplayingTagsEnabled),
                {
                    title: 'Confirmation',
                    body: (
                        <>
                            This will enable tags on this vehicle permanently, are you sure you want to turn displaying
                            tags on this vehicle?
                        </>
                    ),
                    btnClass: 'btn-primary',
                }
            );
        }
    };

    const toggleSortOrder = order => {
        switch (order) {
            case SORT_ORDER.asc:
                return SORT_ORDER.desc;
            case SORT_ORDER.desc:
                return SORT_ORDER.none;
            case SORT_ORDER.none:
                return SORT_ORDER.asc;
            default:
                return order;
        }
    };

    const compareValues = (a, b) => {
        if (typeof a === 'number' && typeof b === 'number') {
            return a - b;
        } else if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
        } else {
            return String(a).localeCompare(String(b));
        }
    };

    const handleColumnSort = columnName => {
        const updatedSortedColumns = [...sortedColumn];
        const updatedSortOrders = { ...sortOrder };

        if (updatedSortedColumns.includes(columnName)) {
            updatedSortOrders[columnName] = toggleSortOrder(updatedSortOrders[columnName]);
            if (updatedSortOrders[columnName] === SORT_ORDER.none) {
                const index = updatedSortedColumns.indexOf(columnName);
                if (index !== -1) updatedSortedColumns.splice(index, 1);
            }
        } else {
            updatedSortedColumns.push(columnName);
            updatedSortOrders[columnName] = SORT_ORDER.asc;
        }

        setOriginalOrder(false);
        setSortedColumn(updatedSortedColumns);
        setSortOrder(updatedSortOrders);
    };

    const getColumnValue = (data, columnName) => {
        if (columnName === 'vehicleId') return data.vehicleId;
        if (columnName === 'vehicle') return `${data.year.yearValue} ${data.model.modelName} ${data.trim.trimName}`;
        else if (columnName === 'locales') return data.locales ? data.locales : [];
        else if (columnName === 'trim')
            return data.isTrimLevelFilteringEnabled ? data.isTrimLevelFilteringEnabled : false;
        else if (columnName === '3dModel') return data.visualModel ? data.visualModel.visualModelName : '';
        else if (columnName === 'oemModel') return data.oemModel ? data.oemModel.oemModelName : '';
        else if (columnName === 'tags')
            return data.taggerStatistics.activeIncompletedTagCount !== 0 && !data.isDisplayingTagsEnabled
                ? ''
                : data.isDisplayingTagsEnabled;

        return data[columnName];
    };

    useEffect(() => {
        if (originalOrder) {
            setSortedVehicles(searchedVehicles);
        } else {
            const sortedData = [...searchedVehicles].sort((a, b) => {
                for (const column of sortedColumn) {
                    const aValue = getColumnValue(a, column);
                    const bValue = getColumnValue(b, column);

                    // Handling for empty locales so they always remain
                    // at the bottom when filtering by locales
                    if (column === 'locales') {
                        if (aValue.length === 0 && bValue === 0) return 0;
                        else if (aValue.length === 0) return 1;
                        else if (bValue.length === 0) return -1;
                    }

                    if (sortOrder[column] === SORT_ORDER.asc) {
                        const comparison = compareValues(aValue, bValue);
                        if (comparison !== 0) {
                            return comparison;
                        }
                    } else if (sortOrder[column] === SORT_ORDER.desc) {
                        const comparison = compareValues(bValue, aValue);
                        if (comparison !== 0) {
                            return comparison;
                        }
                    }
                }
                return 0;
            });
            setSortedVehicles(sortedData);
        }
    }, [searchedVehicles, sortedColumn, sortOrder, originalOrder]);

    return {
        searchValue,
        setSearchValue,
        isLoading,
        searchedVehicles,
        updateTrimFiltering,
        handleBulkEditButtonClick,
        selectedVehicleIds,
        isBulkCheckboxChecked,
        handleCheckboxChange,
        handleBulkCheckboxChange,
        handleCheckboxKeyDown,
        handleCheckboxKeyUp,
        isBulkAssignModalOpen,
        handleModalClose,
        handleModalSave,
        oemId,
        visualModelViewerModel,
        setVisualModalViewerModel,
        handleVehicleDisplayingTagsYesButtonClick,
        confirmationModelRef,
        vehicles,
        filters,
        handleAddFilterButtonClick,
        handleRemoveFilterButtonClick,
        handleUpdate,
        handleSearchButtonClick,
        sortedVehicles,
        handleColumnSort,
        sortedColumn,
        sortOrder,
    };
};

export default useVehicleManagementTool;
