import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import Select from 'react-select';
import './FilterModal.scss';
import { dataMakes, dataModels } from './data/dataModelMakes';

const yearOperatorOptions = [
    { label: 'range', value: 'range' },
    { label: '==', value: 'equal' },
];

export const PlaygroundFilterModal = () => {
    const [modal, setModal] = useState(false);
    const [selectedMake, setSelectedMake] = useState(null);
    const [modelOptions, setModelOptions] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYearOperator, setSelectedYearOperator] = useState(yearOperatorOptions);

    useEffect(() => {
        setModelOptions(
            dataModels
                .filter(model => (selectedMake ? model.oemId === selectedMake.value : true))
                .map(model => ({
                    label: `${dataMakes.find(make => make.oemId === model.oemId).oemName} ${model.modelName}`,
                    value: model.modelId,
                }))
        );
    }, [selectedMake]);

    const makeOptions = dataMakes.map(make => ({ label: make.oemName, value: make.oemId }));

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button color="primary" onClick={toggle}>
                Add Filter
            </Button>
            <Modal isOpen={modal} size="lg" toggle={toggle}>
                <ModalHeader toggle={toggle}>Add filter</ModalHeader>
                <ModalBody>
                    <div className="make-section">
                        <label htmlFor="make-select">Make</label>
                        <Select
                            id="make-select"
                            options={makeOptions}
                            placeholder="Choose Make"
                            isDisabled={selectedModel}
                            value={selectedMake}
                            onChange={setSelectedMake}
                        />
                    </div>

                    <div className="model-section">
                        <label htmlFor="model-select">Model</label>
                        <Select
                            id="model-select"
                            options={modelOptions}
                            placeholder="Choose Model"
                            value={selectedModel}
                            onChange={setSelectedModel}
                        />
                    </div>

                    <div className="year-section">
                        <label htmlFor="year-operator-select">Year</label>
                        <Select
                            id="year-operator-select"
                            options={yearOperatorOptions}
                            placeholder="Choose operator"
                            value={selectedYearOperator}
                            onChange={setSelectedYearOperator}
                        />
                        {selectedYearOperator.label === 'range' ? (
                            <>
                                <Input type="number" className="year-range-from-input" placeholder="from" />
                                <Input type="number" className="year-range-to-input" placeholder="to" />
                            </>
                        ) : (
                            <Input type="number" className="year-input" placeholder="year" />
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <Button color="success">Apply</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
